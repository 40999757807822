import './bootstrap';
import '../css/app.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import ReactGA from 'react-ga4';
import Clarity from '@microsoft/clarity';
import * as Sentry from "@sentry/react";

// Clarity
const projectId = "qbotde5xs0"

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const appEnv = import.meta.env.VITE_APP_ENV || 'production';

createInertiaApp({
    title: (title) => `${title} | ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        if(appEnv === 'production') {
            // Google Analytics
            ReactGA.initialize('G-DBTX11MVX1');
            // Clarity
            Clarity.init(projectId);

            Sentry.init({
                dsn: "https://9325b960002d05709d8fe3d64126ee57@o4508911778988032.ingest.us.sentry.io/4509018376896512",
                integrations: [
                    Sentry.replayIntegration()
                ],
                replaysSessionSampleRate: 0,
                replaysOnErrorSampleRate: 1.0
            });
        }
        
        const root = createRoot(el);

        root.render(<App {...props} />);
    },
    progress: {
        color: '#4B5563',
    },
});
